import './Reasons.css';
import image1 from '../../assests/image1.png';
import image2 from '../../assests/image2.png';
import image3 from '../../assests/image3.png';
import image4 from '../../assests/image4.png';
import nb from '../../assests/nb.png';
import addidas from '../../assests/adidas.png';
import nike from '../../assests/nike.png';
import tick from '../../assests/tick.png';



const Reasons = () => {
    return ( 
        <>
            <div className="reasons" id='reasons'>
                <div className="left-r">
                    <img src={image1} alt="" />
                    <img src={image2} alt="" />
                    <img src={image3} alt="" />
                    <img src={image4} alt="" />
                </div>
                <div className="right-r">
                    <span>دلایلی که</span>
                    <div>
                        <span className='stroke-text'>چرا</span>
                        <span> سایت ما؟</span>
                    </div>

                    <div className='details-r'>
                    <div>
                        <img src={tick} alt=""></img>
                        <span>بیش از 140 مربی حرفه ای</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span>تمرینات هوشمندانه و سریع تر از قبل</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span> برنامه ی رایگان برای اعضای جدید</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span>همکاران قابل اعتماد</span>
                    </div>
                    </div>
                    <span
                        style={{
                            color: 'var(--gray)',
                            fontWeight: 'normal',
                        }}
                    >همکاران ما</span>

                    <div className="partners">
                        <img src={nb} alt="" />
                        <img src={addidas} alt="" />
                        <img src={nike} alt="" />
                    </div>
                </div>
            </div>
        </>
     );
}
 
export default Reasons;
<>

</>