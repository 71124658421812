import image1 from '../assests/t-image1.png';
import image2 from '../assests/t-image2.jpg';
import image3 from '../assests/t-image3.jpg';

export const testimonialsData = [
  {
    image: image1,
    review:
      'من با انتخاب درست باشگاه نوین فیت و انتخاب برنامه درست تحت نظر مربی حرفه ای به بدن ایده آل خودم رسیدم',
    name: 'محمد سید آقایی',
    status: 'مربی',
  },
  {
    image: image2,
    review:
      'انجام عادات مثبت هر چند کوچک اما به طور مستمر باعث ایجاد تغییرات بزرگ در زندگی میشود ',
    name: 'سید آقایی',
    status: 'مربی',
  },
  {
    image: image3,
    review:
      ' اینجا تحت نظر بهترین مربیان با علمی ترین راهکارها شما را آموزش خواهیم داد و به نتیجه دلخواه خواهیم رساند',
    name: 'محمد',
    status: 'مربی',
  },
];
