import './Plans.css';
import {plansData} from '../../data/plansData';
import whiteTick from '../../assests/whiteTick.png';

const Plans = () => {
    return ( 
        <div className="plans-container" id='plans'>
            <div className="blur plans-blur-1"></div>
            <div className="blur plans-blur-2"></div>

            <div className="programs-header">
                <span className='stroke-text'>آماده برای شروع</span>
                <span>ماجراجویی</span>
                <span className='stroke-text'>همراه ما</span>
            </div>

            {/* plans cards */}
            <div className="plans">
                {plansData.map((plan, i)=>{
                   return( <div className="plan" key={i}>
                   {plan.icon}
                   <span>{plan.name}</span>
                   <span>ت {plan.price}</span>

                   <div className="features">
                       {plan.features.map((feature, i)=>{
                           return(<div className="feature">
                               <img src={whiteTick} alt="" />
                               <span>{feature}</span>
                           </div>)
                       })}
                   </div>

                   <div>
                    <span>مشاهده مزایای بیشتر -&gt;</span>
                   </div>
                   <button className='btn'>ثبت نام</button>
               </div>)
                })
                    
                }
            </div>
        </div>
     );
}
 
export default Plans;