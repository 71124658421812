import './Programs.css';
import {programsData} from '../../data/programsData';
import RightArrow from '../../assests/rightArrow.png';

const Programs = () => {
    return ( 
        <>
            <div className="programs" id='programs'>
                {/* header */}
                <div className="programs-header">
                    <span className='stroke-text'>دنبال کردن </span>
                    <span>برنامه ها </span>
                    <span className='stroke-text'>برای شروع</span>
                </div>

                <div className="program-categories">
                    {
                        programsData.map((program)=>{
                          return(<div className="category">
                                {program.image}
                                <span>{program.heading}</span>
                                <span>{program.details}</span>
                                <div className="join-now">
                                    <span>ثبت نام</span>
                                    <img src={RightArrow} alt="" />
                                </div>
                            </div>)
                        })
                    }
                </div>
            </div>
        </>
     );
}
 
export default Programs;