import React from 'react';
import './Hero.css';
import Header from '../header/Header';
import hero_image from '../../assests/hero_image.png';
import hero_image_back from '../../assests/hero_image_back.png';
import Heart from '../../assests/heart.png';
import Calories from '../../assests/calories.png';
import { motion } from "framer-motion";
import NumberCounter from 'number-counter';

const Hero = () => {
    const transition = {type: 'spring', duration: 3};
    const mobile = window.innerWidth<=768 ?true : false; 
    return ( 
        <>
            <div className='hero' id='hero'>
                <div className="blur hero-blur"></div>
                <div className='left-h'>
                    <Header />

{/* ad section */}
                    <div className='the-best-ad'>
                        <motion.div
                            initial={{left: mobile? '140px':'200px'}}
                            whileInView={{left: '8px'}}
                            transition={{...transition, type: 'tween'}}
                        ></motion.div>
                        <span>بهترین باشگاه تناسب اندام در کل شهر</span>
                    </div>

{/* header text section */}
                    <div className="hero-text">
                        <div>
                            <span className='stroke-text'>اندام </span>
                            <span>ایده آل</span>
                        </div>
                        <div>
                            <span>خودت رو بساز</span>
                        </div>
                        <div>
                            <span>
                                اینجا ما بهت آموزش میدیم اندام ایده آل خودت رو بسازی و زندگی سالم تری داشته باشی
                            </span>
                        </div>
                    </div>

{/* figures */}
                    <div className="figures">
                        <div>
                            <span>
                                <NumberCounter end={140} start={100} delay='4' preFix='+' />
                            </span>
                            <span>مربیان حرفه ای</span>
                        </div>
                        <div>
                            <span>
                            <NumberCounter end={978} start={800} delay='4' preFix='+' />
                            </span>
                            <span>تعداد اعضا</span>
                        </div>
                        <div>
                            <span>
                            <NumberCounter end={50} start={0} delay='4' preFix='+' />
                            </span>
                            <span>برنامه های بدنسازی</span>
                        </div>
                    </div>

                    {/* hero - buttons */}
                    <div className="hero-buttons">
                        <button className="btn">شروع کردن</button>
                        <button className="btn">اطلاعات بیشتر</button>
                    </div>
                </div>
                <div className='right-h'>
                    <button className='btn'>ثبت نام</button>

                    <motion.div
                        initial={{right: '-1rem'}}
                        whileInView={{right: '4rem'}}
                        transition={transition}
                        className='heart-rate'>
                        <img src={Heart} alt="" />
                        <span>ضربان قلب</span>
                        <span>116 bpm</span>
                    </motion.div>

                    {/* hero-images */}
                    <img src={hero_image} alt='hero' className='hero-image'/>
                    <motion.img
                        initial={{right: '11rem'}}
                        whileInView={{right: '20rem'}}
                        transition={transition}
                        src={hero_image_back} alt='hero' className='hero-image-back'
                    />

                    {/* calories */}
                    <motion.div
                            initial={{right: '37rem'}}
                            whileInView={{right: '28rem'}}
                            transition={transition}
                            className="calories"
                        >
                        <img src={Calories} alt="" />
                        <div>
                            <span>کالری مصرفی</span>
                            <span>220 کیلوکالری</span>
                        </div>
                    </motion.div>

                </div>

            </div>
        </>
     );
}
 
export default Hero;