import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Join.css';

const Join = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_5wq26ep', 'template_ebx8nke', form.current, '43jIn2Jh1nqmUd3lu')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

    return ( 
        <div className="join" id="join-us">
            <div className="left-j">
                <hr />
                <div>
                    <span className='stroke-text'>آماده برای</span>
                    <span>ارتقای</span>
                </div>
                <div>
                    <span>اندام شما</span>
                    <span className='stroke-text'>همراه ما</span>
                </div>
            </div>
            <div className="right-j">
                <form ref={form} className="email-container" onSubmit={sendEmail}>
                    <input type="email" name='user_email' placeholder='آدرس ایمیل خود را وارد کنید' />
                    <button className='btn btn-j'>ثبت نام</button>
                </form>
            </div>
        </div>
     );
}
 
export default Join;