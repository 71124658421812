import './Footer.css';
import Github from '../../assests/github.png';
import Instagrem from '../../assests/instagram.png';
import LinkedIn from '../../assests/linkedin.png';
import Logo from '../../assests/logo.png';


const Footer = () => {
    return ( 
        <div className="footer-container" id='footer'>
            <hr />
            <div className="footer">
                <div className="social-media">
                    <img src={Github} alt="" />
                    <img src={Instagrem} alt="" />
                    <img src={LinkedIn} alt="" />
                </div>
                <div className="logo-f">
                    <img src={Logo} alt="" />
                </div>
            </div>
            <div className="blur footer-blur-1"></div>
            <div className="blur footer-blur-2"></div>

        </div>
     );
}
 
export default Footer;