import './Header.css';
import Logo from '../../assests/logo.png';
import Bars from '../../assests/bars.png';
import { useState } from 'react';
import {Link} from 'react-scroll';


const Header = () => {

    const mobile = window.innerWidth<=768 ?true : false; 
    const [menuOpened, setMenuOpened] = useState(false);

    return ( 
        <div className="header">
            
            <img src={Logo} alt='logo' className='logo' />
            {(menuOpened === false && mobile === true)? (
                <div className='hamber' onClick={()=>{setMenuOpened(true)}}>
                    <img src={Bars} alt="" style={{width: '1.5rem',height: '1.5rem'}}/>
                </div>
            ):(
                <ul className='header-menu'>
                <li>
                    <Link
                        onClick={()=>{setMenuOpened(false)}}
                        activeClass='active'
                        to='hero'
                        spy={true}
                        smooth={true}
                    >خانه</Link>
                </li>
                <li>
                    <Link
                         onClick={()=>{setMenuOpened(false)}}
                         to='programs'
                        spy={true}
                        smooth={true}
                    >برنامه ها</Link>
                </li>
                <li>
                    <Link
                         onClick={()=>{setMenuOpened(false)}}
                         to='join-us'
                        spy={true}
                        smooth={true}
                    >چرا ما؟</Link>
                </li>
                <li>
                    <Link
                         onClick={()=>{setMenuOpened(false)}}
                         to='plans'
                        spy={true}
                        smooth={true}
                    >نقشه راه</Link>
                </li>
                <li>
                    <Link
                         onClick={()=>{setMenuOpened(false)}}
                        to='testimonials'
                        spy={true}
                        smooth={true}
                    >گواهی نامه ها</Link>
                </li>
            </ul>
            )}
            
        </div>
     );
}
 
export default Header;